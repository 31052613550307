/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export type SupportedEnvironments = 'production' | 'development' | 'localhost';

export interface IEnvironmentConfig {
	production: boolean;
	envName: string;
	googleClientId: string;
	supportEmail: string;
}

export class EnvironmentConfig implements IEnvironmentConfig {
	production: boolean;
	envName: SupportedEnvironments;
	googleClientId: string;
	supportEmail: string;

	constructor(args: IEnvironmentConfig) {
		const {
			production,
			envName,
			googleClientId,
			supportEmail,
		} = args;

		this.production = production;
		this.googleClientId = googleClientId;
		this.supportEmail = supportEmail;
		switch (envName) {
			case 'localhost':
			case 'production':
			case 'development': {
				this.envName = envName;
			} break;

			default: {
				throw new Error('Unsupported configuration!');
			}
		}
	}
}

export interface IEnvironmentService {
	resolve(): EnvironmentConfig;
}

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService implements IEnvironmentService {
	resolve(): EnvironmentConfig {
		return new EnvironmentConfig(environment);
	}
}
/* eslint-enable max-classes-per-file */
